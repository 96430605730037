








































































import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Icon from "@/components/reusable/Icon.vue";
//import Toast from "@/components/reusable/Toast.vue";
import AttributeService from "@/services/attribute_service";
import AttributeMenu from "@/components/attribute/AttributeMenu.vue";
import { AttributeModel } from "@/models/attribute";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { ListsGetters, ListsActions } from "@/store/modules/lists/types";
import { CategoryModel } from "@/models/category";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { AuthError, NotFoundError } from "@/services/error_service";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Wysiwyg,
    FormInput,
    Icon,
    //    Toast,
    AttributeMenu
  }
})
export default class AttributeEditor extends Vue {
  protected showWarningModal = false;
  protected attributeService = new AttributeService();
  protected isNew = false;
  protected attribute = {} as AttributeModel;
  protected id = 0;
  protected deleteData: AttributeModel[] = [];
  protected className = "success";
  protected toast = false;
  protected messageHtml = "";
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @Validations()
  validations() {
    return {
      attribute: {
        display_name: { required }
      }
    };
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: AttributeModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "attribute" },
        { height: "auto", adaptive: true }
      );
    });
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
  }

  created() {
    if (!this.$route.params.id) {
      this.isNew = true;
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      this.getSingleAttribute();
    }
  }

  protected cancel(): void {
    this.$router.go(-1);
  }
  protected saveKeypress(): void {
    this.save();
  }

  protected closeToast(): void {
    this.toast = false;
  }

  protected save(): void {
    this.$v.attribute.$touch();
    if (!this.$v.$invalid) {
      this.showWarningModal = false;
      if (this.isNew) {
        this.postNew();
      } else {
        this.saveExisting();
      }
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function() {
            next();
          },
          function() {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  protected showToast(message: string, className: string): void {
    this.messageHtml = message;
    this.className = className;
    this.toast = true;
  }

  protected async postNew(): Promise<void> {
    try {
      const res = await this.attributeService.createNewAttribute(
        this.attribute
      );
      this.$router.push({
        path: "/attribute/edit/" + res.id,
        query: { created: encodeURI(`${res.display_name}`), type: "attribute" }
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async getSingleAttribute(): Promise<void> {
    this.setLoading(true);
    try {
      const res: AttributeModel = await this.attributeService.getSingleAttribute(
        this.id
      );
      this.attribute = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) }
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    this.setLoading(false);
  }

  protected async saveExisting(): Promise<void> {
    try {
      await this.attributeService.saveAttribute(
        this.attribute,
        this.attribute.id as number
      );
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Attribute <strong>${this.attribute.display_name}</strong> has been ${this.action}.`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.showWarningModal = false;
    try {
      await this.attributeService.deleteAttribute(id);
      this.$router.push({
        path: "/attribute",
        query: {
          deleted: encodeURI(`${this.attribute.display_name}`),
          type: "attribute"
        }
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  get action(): string {
    let action = "saved";
    if (!this.$route.params.id) {
      action = "created";
    }
    return action;
  }
}
